import EmptyState from '@/components/core/EmptyState';
import LoadingIndicator from '@/components/core/LoadingIndicator';
import {GetCurrentUser} from '@/context/UserContext';
import useGlobalEventEmitterEvent from '@/events/useGlobalEventEmitterEvent';
import type {User} from '@/model/user/types';
import {constructErrorMeta, displayClientError, type ErrorMeta} from '@/utils/errors';
import {useSignals} from '@preact/signals-react/runtime';
import type {ActionFunctionArgs, LoaderFunctionArgs, TypedDeferredData} from '@remix-run/node';
import {
  Await,
  defer,
  Navigate,
  redirect,
  useActionData,
  useAsyncError,
  useLoaderData,
  useNavigation,
  useRevalidator,
} from '@remix-run/react';
import clsx from 'clsx';
import type {FC} from 'react';
import {Suspense, useEffect} from 'react';

type LivanDataFunctionDataWithoutLoading<T> = XOR<
  {
    errors: ErrorMeta;
  },
  {
    result: T;
  }
>;

type LivanDeferredLoaderFunctionData<T> = {
  deferred: TypedDeferredData<LivanDataFunctionDataWithoutLoading<T>>;
};

export type LivanDataFunctionData<T> =
  | (LivanDataFunctionDataWithoutLoading<T> & {
      loading: false;
    })
  | {
      errors?: never;
      loading: true;
      result?: never;
    };

function handleDataFunctionError(error: Error) {
  const errorMeta = constructErrorMeta(error);
  if (errorMeta) {
    return {
      errors: errorMeta,
    };
  }
  throw error;
}

export function WithDeferredLoaderErrorHandling<T1>(
  fn: (args: LoaderFunctionArgs, user: User | null) => Promise<T1>,
  options?: {
    ignoreUserFetch?: boolean; // only for the root route!!
    allowsUnauthenticated?: boolean;
  },
) {
  const {ignoreUserFetch, allowsUnauthenticated} = options || {};
  const loaderWrapper = async function loaderWrapper(args: Parameters<typeof fn>[0]) {
    let user: User | null = null;
    if (!ignoreUserFetch) {
      user = await GetCurrentUser();
    }
    if ('serverLoader' in args && !allowsUnauthenticated && !user) {
      const {request} = args;
      const redirectTo = new URL(request.url).pathname;
      throw redirect(`/log-in?redirectTo=${redirectTo}`);
    }
    const resultPromise = fn(args, user);
    return defer({
      deferred: resultPromise
        .then(async (result) => {
          return {result};
        })
        .catch(handleDataFunctionError),
    });
  };
  return loaderWrapper;
}

export function WithActionErrorHandling<T1>(fn: (args: ActionFunctionArgs) => Promise<T1>) {
  const actionWrapper = async function actionWrapper(
    args: Parameters<typeof fn>[0],
  ): Promise<LivanDataFunctionDataWithoutLoading<T1>> {
    try {
      const result = await fn(args);
      return {result};
    } catch (e) {
      return handleDataFunctionError(e as Error);
    }
  };
  return actionWrapper;
}

function useLoaderErrorStatus(errors: ErrorMeta | undefined) {
  useEffect(() => {
    displayClientError(errors, {
      fieldErrorMessage: 'Invalid parameters in URL',
    });
  }, [errors]);
}

function useActionErrorStatus(errors: ErrorMeta | undefined) {
  useEffect(() => {
    displayClientError(errors);
  }, [errors]);
}

function DeferredComponent(props) {
  useSignals();
  const {Component, loaderData, actionData} = props;
  useLoaderErrorStatus(loaderData?.errors);
  if (loaderData?.errors?.status != null) {
    if (loaderData.errors.status === 403) {
      return (
        <EmptyState
          type="Forbidden"
          description={loaderData.errors.message}
        />
      );
    } else if (loaderData?.errors.status === 400) {
      return (
        <EmptyState
          type="BadRequest"
          description={loaderData.errors.message}
        />
      );
    } else if (loaderData?.errors.status === 404) {
      return (
        <EmptyState
          type="PageNotFound"
          description={loaderData.errors.message}
        />
      );
    } else {
      return <EmptyState type="GenericError" />;
    }
  }

  return Component(props, {
    loaderData,
    actionData,
  });
}

// hack to get remix to play nicely with throwing redirects within deferred functions
function AwaitRedirect(params) {
  const error = useAsyncError();
  if (error instanceof Response && error.status >= 300 && error.status < 400) {
    return <Navigate to={error.headers.get('Location')!} />;
  }
  throw error;
}

export function WithLivanComponentWrapper<P, LoaderResult, ActionResult>({
  clientLoader,
  clientAction,
  Component,
}: {
  clientLoader?: ReturnType<typeof WithDeferredLoaderErrorHandling<LoaderResult>>;
  clientAction?: ReturnType<typeof WithActionErrorHandling<ActionResult>>;
  Component: (
    ...args: [
      Parameters<FC<P>>[0],
      {
        loaderData: LivanDataFunctionDataWithoutLoading<LoaderResult>;
        actionData: LivanDataFunctionData<ActionResult>;
      },
    ]
  ) => ReturnType<FC<P>>;
}) {
  function LivanComponentWrapper(props: P) {
    useSignals();
    const deferredLoaderData = useLivanDeferredLoaderData<LoaderResult>();
    const actionData = useLivanActionData<ActionResult>();
    useActionErrorStatus(actionData?.errors);
    const revalidator = useRevalidator();

    useGlobalEventEmitterEvent('websocket-reconnected', () => {
      if (clientLoader) {
        revalidator.revalidate();
      }
    });

    if (deferredLoaderData?.deferred instanceof Promise) {
      return (
        <Suspense fallback={<SuspenseFallback centered />}>
          <Await
            resolve={deferredLoaderData.deferred}
            errorElement={<AwaitRedirect />}
          >
            {({result, errors}) => {
              return (
                <DeferredComponent
                  Component={Component}
                  loaderData={{result, errors}}
                  actionData={actionData}
                />
              );
            }}
          </Await>
        </Suspense>
      );
    }

    return Component(props, {
      loaderData: deferredLoaderData?.deferred?.data,
      actionData,
    });
  }

  // overwrite name for dev-tools so that all components don't appear as `LivanComponentWrapper` in react tree
  Object.defineProperty(LivanComponentWrapper, 'name', {
    writable: true,
    value: Component.name,
  });

  return LivanComponentWrapper;
}

function useLivanDeferredLoaderData<T>(): LivanDeferredLoaderFunctionData<T> {
  const loaderData = useLoaderData<LivanDeferredLoaderFunctionData<T>>() || {};
  return loaderData;
}

function useLivanLoaderData<T>(): LivanDataFunctionDataWithoutLoading<T> {
  const loaderData = useLoaderData<LivanDataFunctionDataWithoutLoading<T>>() || {};
  return loaderData;
}

function useLivanActionData<T>(): LivanDataFunctionData<T> {
  const navigation = useNavigation();
  const actionData = useActionData<LivanDataFunctionDataWithoutLoading<T>>();
  if (navigation.state === 'submitting') {
    return {
      loading: true,
    };
  }
  // @ts-expect-error not sure how to fix this
  return {
    ...actionData,
    loading: false,
  };
}

type SuspenseFallbackProps = {
  className: string;
  centered?: boolean;
};
export function SuspenseFallback(props) {
  const {className, centered} = props;
  return (
    <div className={clsx(centered && 'size-full flex items-center justify-center p-10', className)}>
      <LoadingIndicator
        color="black"
        size="md"
      />
    </div>
  );
}

export function WithSuspense<P>(Component: React.ComponentType<P>) {
  return function WrappedComponent(props: React.PropsWithChildren<P>) {
    return (
      <Suspense fallback={<SuspenseFallback />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

export function constructTitleMeta(...subtitles: string[]) {
  return {
    title: [...subtitles, 'Livan'].filter(Boolean).join(' – '),
  };
}
