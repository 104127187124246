import '@/components/core/Button.css';
import React, {useCallback} from 'react';
import type {ButtonHTMLAttributes, MouseEventHandler} from 'react';
import clsx from 'clsx';
import LoadingIndicator from '@/components/core/LoadingIndicator';
import LivanLink, {type LivanLinkProps} from '@/components/core/LivanLink';
import {stopPropagationAndPreventDefault} from '@/utils/stopPropagationAndPreventDefault';
import {useSignals} from '@preact/signals-react/runtime';

export type ButtonSize = 'md' | 'sm';

export type ButtonFlavor = 'default' | 'link' | 'outline' | 'outline-dashed';

type ButtonState = 'enabled' | 'disabled';

const commonClassNames: Record<ButtonState, string> = {
  enabled: 'cursor-pointer',
  disabled: 'cursor-not-allowed',
};

const commonClassNamesByFlavor: Record<ButtonFlavor, Record<ButtonState, string>> = {
  default: {
    enabled: 'uppercase bg-livan-black text-white',
    disabled: 'uppercase bg-livan-black text-white',
  },
  link: {
    enabled: 'underline',
    disabled: '',
  },
  outline: {
    enabled: 'bg-white border',
    disabled: 'bg-white border',
  },
  'outline-dashed': {
    enabled: 'bg-white border',
    disabled: 'bg-white border',
  },
};

const classNamesByFlavorAndStyleType: Record<
  ButtonFlavor,
  Record<StyleType, Record<ButtonState, string>>
> = {
  default: {
    default: {
      enabled: 'hover:opacity-80',
      disabled: 'opacity-60',
    },
    warning: {
      enabled: 'hover:opacity-80',
      disabled: 'opacity-60',
    },
    destructive: {
      enabled: 'text-white bg-livan-red hover:bg-red-600',
      disabled: 'bg-red-400',
    },
  },
  link: {
    default: {
      enabled: 'text-livan-black hover:text-livan-black/60',
      disabled: 'text-livan-black/75',
    },
    warning: {
      enabled: 'text-livan-black hover:text-livan-black/60',
      disabled: 'text-livan-black/75',
    },
    destructive: {
      enabled: 'text-livan-red',
      disabled: 'text-livan-red/75',
    },
  },
  outline: {
    default: {
      enabled: 'border-livan-black hover:bg-livan-black/10',
      disabled: 'border-livan-gray',
    },
    warning: {
      enabled: 'border-yellow-600 hover:bg-yellow-600/10',
      disabled: 'border-yellow-600/75',
    },
    destructive: {
      enabled: 'border-livan-red text-livan-red',
      disabled: 'border-livan-red/75 text-livan-red/75',
    },
  },
  'outline-dashed': {
    default: {
      enabled: 'border-dashed border-livan-black hover:bg-livan-black/10',
      disabled: 'border-dashed border-livan-gray',
    },
    warning: {
      enabled: 'border-dashed border-yellow-600 hover:bg-yellow-600/10',
      disabled: 'border-dashed border-yellow-600/75',
    },
    destructive: {
      enabled: 'border-dashed border-livan-red text-livan-red',
      disabled: 'border-dashed border-livan-red/75 text-livan-red/75',
    },
  },
};

type Props = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> & {
  className?: string;
  loading?: boolean;
  loadingText?: string;
  content?: string;
  size?: ButtonSize;
  flavor?: ButtonFlavor;
  styleType?: StyleType;
  fullWidth?: boolean;
  rounded?: boolean;
} & XOR<
    {
      onClick: MouseEventHandler;
    },
    {
      type: 'submit';
    },
    {
      to: LivanLinkProps['to'];
    }
  >;

export default function Button(props: Props) {
  useSignals();
  const {
    className,
    onClick,
    content,
    children,
    loading,
    loadingText,
    size = 'md',
    styleType = 'default',
    flavor = 'default',
    fullWidth,
    rounded = true,
    to,
    ...rest
  } = props;

  const disabled = props.disabled || loading;

  const styleTypeCommonClassnames = classNamesByFlavorAndStyleType[flavor][styleType];
  const flavorCommonClassNames = commonClassNamesByFlavor[flavor];
  const Component = to ? LivanLink : 'button';
  const handleClick = useCallback(
    function (e) {
      if (onClick) {
        stopPropagationAndPreventDefault(e);
        onClick(e);
      }
    },
    [onClick],
  );
  const componentProps = to
    ? {
        type: 'button',
        to,
      }
    : {};

  return (
    // @ts-expect-error not sure how do conditionally turn off `type` complaining
    <Component
      {...rest}
      {...componentProps}
      className={clsx(
        className,
        'Button',
        'focus:outline-2 focus:outline-offset-2',
        fullWidth && 'w-full',
        size === 'md' && 'px-3 py-2',
        size === 'sm' && 'text-sm px-2 py-1',
        rounded && 'rounded-md',
        'p-4 inline-flex items-center justify-center font-semibold',
        'relative',
        disabled ? commonClassNames.disabled : commonClassNames.enabled,
        disabled ? flavorCommonClassNames.disabled : flavorCommonClassNames.enabled,
        disabled ? styleTypeCommonClassnames.disabled : styleTypeCommonClassnames.enabled,
      )}
      onClick={!disabled ? handleClick : undefined}
      disabled={disabled}
    >
      <span className={clsx(loading && 'opacity-0')}>{content || children}</span>
      {loading && <LoadingIndicator className={clsx('absolute opacity-100')} />}
    </Component>
  );
}
