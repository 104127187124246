import clsx from 'clsx';

const ColorToStyles = {
  white: 'text-white',
  black: 'text-black',
};

const SizeToStyles = {
  sm: 'h-5 w-5',
  md: 'h-10 w-10',
  lg: 'h-16 w-16',
};

type Size = keyof typeof SizeToStyles;
type Color = keyof typeof ColorToStyles;

interface Props {
  className?: string;
  color?: Color;
  size?: Size;
}

export default function LoadingIndicator(props: Props) {
  const {className, color = 'white', size = 'sm'} = props;
  const sizeStyles = SizeToStyles[size];
  const colorStyles = ColorToStyles[color];
  return (
    <svg
      className={clsx('LoadingIndicator animate-spin', className, colorStyles, sizeStyles)}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      aria-busy
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}
